<template>
     <div class="wrapper">
          <div class="header animate__animated animate__bounceInRight">
            <img class="logo" src="https://static.yihu365.cn/img/wx-uni/logo_kts.png" alt="">
            <div class="search_box">
                <div class="search_main">
                  <img src="https://static.yihu365.cn/img/h5Img/shop/search.png" alt="">
                  <input @focus="toSearch" type="text" placeholder=请输入商品名称>
                  <span>搜索</span>
                </div>
            </div>
            <div class="menu" @click="showBox">
              <img src="https://static.yihu365.cn/img/h5Img/shop/menu_02.png" alt="">
            </div>
          </div>
        <div class="fix_cont"></div>
       <van-pull-refresh
           v-model="loading" @refresh="onRefresh"
           animation-duration = '700'
           success-duration = '700'
           head-height = '70'
           pulling-text =''
           loosing-text =''
       >
         <template #pulling="props">
           <div class="gif_box">
             <img
                 class="gif"
                 src="https://static.yihu365.cn/img/h5Img/shop/gif6.gif"
                 :style="{ transform: `scale(${props.distance / 80})` }"
             />
           </div>
         </template>
         <template #loading>
           <div class="gif_box">
<!--             pco599zei.gif-->
             <img
                 class="gif"
                 src="https://static.yihu365.cn/img/h5Img/shop/gif6.gif"
             />
           </div>
         </template>
         <template #loosing>
           <div class="gif_box">
             <img
                 class="gif"
                 src="https://static.yihu365.cn/img/h5Img/shop/gif6.gif"
             />
           </div>
         </template>

          <div class="banner">
              <div class='swiper_cont'>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                  <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="toClassify(1)">
                    <img :src='item.picUrl' />
                  </van-swipe-item>

                </van-swipe>
              </div>
           <div>
             <div class="gg_box">
                 <van-notice-bar
                     :scrollable="true"
                     left-icon = 'https://static.yihu365.cn/img/h5Img/shop/laba3.gif'
                     background="#fff"
                     color="#000"
                 >
                   <van-swipe
                       vertical
                       class="notice-swipe"
                       :autoplay="10000"
                       :touchable="false"
                       :show-indicators="false"
                   >
                     <van-swipe-item v-for="(item,index) in gList">{{item.content}}</van-swipe-item>
                   </van-swipe>
                 </van-notice-bar>
             </div>
           </div>
          </div>
           <div class="icon_box">
             <div class='top'>
               <div v-for='(item,index) in typeList' :key='index' @click="toClassify(index)">
                 <img :src='item.iconUrl'/>
                 <div>{{item.name}}</div>
               </div>
             </div>
           </div>
          <div class="main_box">
            <img class="tit_img" src="https://static.yihu365.cn/img/h5Img/shop/hot_icons.png" alt="">
            <van-row gutter="5">
              <van-col span="12" v-for="(item,index) in listData" :key="item.name" >
                <div class="s_box">
                  <div class="img_box">
                    <img class="rl_pic" :src=item.iconUrl alt="" @click="toShopDetail(item)">
                  </div>

                   <div class="rl_text">
                     <span class="name">{{ item.name }}</span>
                     <span class="info">
<!--                       {{item.description}}-->
                     </span>
                     <div class="p_box">
                        <span class="price">
                          <span class="p_m">¥</span>{{item.price}}
                        </span>
                        <img class="gwc"  src="https://static.yihu365.cn/img/h5Img/shop/add_cart.png" alt="" @click="addCart(item,index)">
                     </div>
                   </div>
                </div>
              </van-col>
            </van-row>
          </div>
       </van-pull-refresh>
     </div>
  <div class="cart_box animate__animated animate__fadeInBottomRight">
<!--    <div class="cart" @click="toCart">-->
<!--      <div class="num" v-if="!cartInfo.goodsSum == 0">{{ cartInfo.goodsSum }}</div>-->
<!--      <img src="https://static.yihu365.cn/img/h5Img/shop/cart2.png" alt="">-->
<!--    </div>-->
    <van-badge :content="cartInfo.goodsSum" @click="toCart">
      <div class="cart">
        <img src="https://static.yihu365.cn/img/h5Img/shop/cart_4.png" alt="">
      </div>

    </van-badge>
  </div>
  <van-overlay :show="show" @click="show = false" class="" class-name="overly">
    <div class="lei_box animate__animated" @click.stop :class="[show?'animate__bounceInDown':'animate__bounceOutRight']">
         <div class="items" @click="toCart">
           <div class="gw">
<!--             <div class="sl" v-if="!cartInfo.goodsSum == 0">{{ cartInfo.goodsSum }}</div>-->
             <van-badge :content="cartInfo.goodsSum" @click="toCart">
<!--               <div class="cart">-->
                 <img src="https://static.yihu365.cn/img/h5Img/shop/cart_z.png" alt="">
<!--               </div>-->

             </van-badge>

           </div>
           <span>购物车</span>
         </div>
        <div class="items" @click="toClassify(0)">
          <img src="https://static.yihu365.cn/img/h5Img/shop/list_z.png" alt="">
          <span>分类</span>
        </div>
        <div class="items" @click="toShopOrder">
          <img src="https://static.yihu365.cn/img/h5Img/shop/form_z.png" alt="">
          <span>我的订单</span>
        </div>
        <div class="items" @click="toAddressList">
          <img src="https://static.yihu365.cn/img/h5Img/shop/location_z.png" alt="">
          <span>收货地址</span>
        </div>
      <div class="items" @click="toAfterList">
        <img src="https://static.yihu365.cn/img/h5Img/shop/comment.png" alt="">
        <span>售后列表</span>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed,reactive } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog ,Toast} from 'vant'
import shopApi from '@axios/shop'
import { channel } from '@/config/channel.js';
export default {
  name: "shop",
  setup(){
    let router = useRouter();
    const store = useStore();
    const route = useRoute();

    //公告
    const bannerList = ref('')

    shopApi.getMallBanner({}).then((res)=> {
       bannerList.value = res.data;
    })

    const typeList = ref("");
    const getTypeList = () => {
      const params = {
        type:1,
        typeId : 0
      }
      shopApi.getTypeList(params).then((res)=> {
        typeList.value = res.data

      })

    }
    getTypeList()

    const gList= ref('')
    const getData = () => {
      const params = {}
      shopApi.getMallAnnouncements(params).then((res)=> {
        console.log(res)
        gList.value = res.data
      })
    }
    getData();
    const getBannerData = () => { //banner
      const params = {}
      shopApi.getMallBanner(params).then((res)=> {
        console.log(res)
      })
    }
    getBannerData();

    const listData = ref('')
    const getCommData = () => { //List
      const params = {
        userId : store.state.uid,
        pageNum : 2,
        pageSize : 20
      }
      shopApi.getGoodsYouLike(params).then((res)=> {
        console.log('list',res)
        listData.value = res.data.pageData;
      })
    }
    getCommData()

    const show = ref(false)
    const showBox = () => {
      show.value = true;
    }


    //cart  add
   const isActive = ref('-1')//动画开关
    const addCart = (item,index) => {
      console.log(index)
      isActive.value = index
      const params = {
        userId : store.state.uid,
        synchronization:[{
          goodsId : item.id,
          goodsNum : 1,
          type : 3 ,
          unitPrice : item.price,
          goodsName: item.name,
          iconUrl : item.iconUrl,
        }]

      }
      shopApi. setSynchronization(params).then((res)=> {
        console.log('Addcart',res)
        cartInfo.value = res.data;
        getCartNums()
      })
    }
    // Cart  nums
    const cartInfo = ref('')
    const getCartNums = () => {
      const params = {
        userId : store.state.uid
      }
      shopApi.getCartInfos(params).then((res)=> {
        console.log('cart',res)
        cartInfo.value = res.data;
      })
    }
    getCartNums()
    //跳转
    const  toCart = () => { //          购物车
      router.push({
        name: 'cart',
        query: {

        }
      })
    }
    const  toClassify= (index) => {//分类
      index = (index == 7? 0 :index);
      router.push({
        name: 'classify',
        query: {
            index: index
        }
      })
    }
    const  toShopOrder= () => {//我的订单
      router.push({
        name: 'shopOrder',
        query: {

        }
      })
    }
    const  toAddressList= () => {// 收货地址
      router.push({
        name: 'addressList',
        query: {
        }
      })
    }
    const  toShopDetail= (item) => {// 收货地址
      router.push({
        name: 'shopDetail',
        query: {
          goodsId : item.id
        }
      })
    }
    const toAfterList =(item)=> {
      router.push({
        name: 'afterList',
        query: {
          // goodsId : item.id
        }
      })
    }
 const toSearch =()=> {
    router.push({
       name:'searchShop'
    })
 }
 //下拉刷新
    const loading = ref(false)
    const onRefresh = () => {
      setTimeout(() => {
        router.replace({
          name:'empty'
        })
        Toast('刷新成功');
        loading.value = false;

      }, 2000);
    };
      return {
        show,
        showBox,
        toCart,
        toClassify,
        toShopOrder,
        toAddressList,
        getData,
        listData,
        addCart,
        getCartNums,
        cartInfo,
        toShopDetail,
        typeList,
        gList,
        bannerList,
        isActive,
        toSearch,
        loading,
        onRefresh,
        toAfterList
      }
  }
}
</script>
<style lang="scss" scoped>
*{
  webkit-text-size-adjust: none;
}
::v-deep(.van-notice-bar__wrap){
  padding-top: 7px;
}
::v-deep(.van-overlay){
  background-color: transparent!important;
}
.wrapper{
   font-family: Avenir,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing:antialiased;

}
.van-search{
  flex-grow: 1;
}
 ::v-deep(.van-search__action){
  display: flex;
  margin-left: 16px;
}
.gg_box{
  ::v-deep(.van-notice-bar__left-icon ){
   font-size: 54px;
  }
  ::v-deep(.van-swipe-item){
    font-size: 27px;
    color:#666;
  }
  margin-top: 10px;
  height:80px;
}
.swiper_cont{
  height:240px;
  img{
    width: 100%;
    height:230px;
    float: left;
    border-radius: 12px;
  }
}
::v-deep(.van-swipe__indicator){
  width: 20px;
  height: 6px;
  border-radius:4px;
}
::v-deep(.van-swipe){
   border-radius: 12px;
}
::v-deep(.van-swipe-item){
  border-radius: 12px;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
::v-deep(.van-notice-bar__left-icon){
  width:70px;
  font-size: 56px;
}
.van-notice-bar{
 padding:0;
  font-size: 24px;
}
.fix_cont{
  width: 100%;
  height:120px;
}
   .header{
     height:120px;
     width: 100%;
     box-sizing: border-box;
     box-shadow:0px 10px 40px -30px #00c291;
     position: fixed;
     left: 0;
     top: 0;
     z-index: 1;
     background:#fff;
     padding: 0px 20px 0 20px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     .logo{
       width: 75px;
       height:75px;

     }
     .search_box{
       flex-grow: 1;
       margin:0 20px;
       .search_main{

         box-sizing: border-box;
         padding:4px;
         display: flex;
         align-items: center;
         border:3px solid #00c291;
         border-radius: 60px;
        justify-content: space-between;
         img{
           width: 36px;
           height: 36px;
           margin-left: 8px;
           margin-right: 8px;
         }
         input{
           color:#666;
           caret-color: #999999;
           font-size: 26px;
           box-sizing: border-box;
           padding-left: 4px;
           border:none;
           height:40px;
           flex-grow: 1;
         }
         ::-webkit-input-placeholder {
           /* WebKit, Blink, Edge */
          font-size: 26px;
           color:#999;
         }
         :-moz-placeholder {
           /* Mozilla Firefox 4 to 18 */
           font-size: 28px;
           color:#999;
         }
         ::-moz-placeholder {
           /* Mozilla Firefox 19+ */
           font-size: 28px;
           color:#999;
         }
         :-ms-input-placeholder {
           /* Internet Explorer 10-11 */
           font-size: 28px;
           color:#999;
         }

         span{
           margin-left: 10px;
           display: block;
           text-align: center;
           background: #00c291;
           color:#FFFFFF;
           font-size: 30px;
            white-space: nowrap;
           box-sizing: border-box;
           padding:10px 20px;
           border-radius: 40px;
         }
       }
     }
     .menu{
       display: flex;
       align-items: center;
       margin-right: 20px;

       img{
         width: 60px;
         height: 60px;
       }
     }
   }
   .banner{
     box-sizing: border-box;
     padding:10px 20px 0;
   }
.top{
  height:310px;
  padding-top:20px;
  padding-bottom:15px;
  display: flex;
  flex-wrap: wrap;
  img{
    width:64px
  }
  &>div{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom:30px;
    &>div{
      font-size: 27px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #313131;
      margin-top:10px
    }
  }

}
.main_box{
  background:#F2F4F5 ;
   .tit_img{
     margin: 30px 0 14px 0;
     width: 100%;
   }
  .van-row{
    box-sizing: border-box;
    padding:0 20px 20px;
  }
  .van-col--12{
    margin-bottom: 10px;
  }
  .s_box{
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    .img_box{
      height:360px;

    }
    .rl_pic{
      width: 100%;
      max-height:480px;
      border-radius: 10px;
    }
    .rl_text{
      box-sizing: border-box;
      margin-top: 6px;
      .name{color:#2c3e50;font-size: 28px;display: block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;padding:0 12px;}
      .info{color:#888;font-size: 26px;display: block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;padding:0 12px;}
      .p_box{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding:16px 18px;
        align-items: center;
        .price{
          color:#FF4A26;height: 50px;
          font-size: 35px;
          .p_m{
            font-size: 30px;
          }
        }
        .gwc{
          width: 48px;
          height: 48px;
        }
      }

    }
  }
}
.cart_box{
  position: fixed;
  right: 80px;
  bottom:80px;
  z-index: 1;
  ::v-deep(.van-badge){
    background: #00c291;
    border-color:#00c291;
  }
  ::v-deep(.van-badge--fixed){
    top:20px;
    right:16px;
  }
}
.cart{
    img{
      margin-top: 15px;
      width: 72px;
      height: 72px;

    }
  position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow:0 0 400px 1px #00c291;
    text-align: center;

  .num{
    font-style: normal;
    font-size: 24px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #FF4A26;
    color:#fff;
    text-align: center;
    line-height: 36px;
    position: absolute;
    right:14px;
    top:14px;
    z-index: 2;
  }
}
.lei_box{
  width: 280px;
  background: #f0f0f0;
  position: absolute;
  right:8px;
  top:128px;
  border-radius: 8px;
  padding:16px;
  box-sizing: border-box;
  ::v-deep(.van-badge){
    background: #00c291;
    border-color: #00c291;
  }
  .items{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    padding:10px;
    border-radius: 8px;
    margin-bottom: 10px;
     img{
       width: 50px;
       height: 50px;
     }
    span{
      margin-left: 18px;
       font-size: 30px;
    }
    .gw{
      position: relative;
      height:50px;
      .sl{
        width: 32px;
        height: 32px;
        position: absolute;
        right:-14px;top:-6px;
        background: #00c291;
        color:#FFFFFF;
        font-size: 20px;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        padding:2px;
      }
    }
  }
  .items:last-child{
     margin-bottom: 0!important;
  }
}
.gif_box{
  width: 100%;


  .gif{
    //margin-top: -22px;
    width: 200px;
   height:auto;
  }
}
.overly{
  background: rgba(0,0,0,0.4);
}
</style>
